<template>
  <div class="video-breakdown flex" style="flex:1">
    <div class="flex flex-column overview-analytics__table" style="flex: 1">
      <div class="video-info">
        <div class="video">
          <div style="padding-bottom: 50px; top: 0">
            <VideoPlayer :options="options" />
          </div>
          <div>
            <h2>Annotation Info</h2>
            <table>
              <tr>
                <th>Link</th>
                <th>Status</th>
              </tr>
              <tr v-for="result in foundSpotlight?.dataloopItems"
                :key="result?.name">
                <td><a :href= '`${result.url}`' target="_blank">{{result.id}}</a></td>
                <td>{{result.status}}</td>
              </tr>
            </table>
          </div>
        </div>
        <div>
          <div>
            <h2>Video Info</h2>
            <table>
              <tr id="video-name">
                <th>VideoName</th>
                <td>{{ foundSpotlight?.videoPath}}</td>
              </tr>
              <tr id="video-filebase">
                <th>Filebase</th>
                <td>{{ foundSpotlight?.filebase}}</td>
              </tr>
              <tr id="video-session">
                <th>Session</th>
                <td>{{ foundSpotlight?.session }}</td>
              </tr>
              <tr id="video-factory">
                <th>Factory</th>
                <td>{{ foundSession?.source }}</td>
              </tr>
              <tr id="video-procedure">
                <th >Procedure</th>
                <td>{{ foundSpotlight?.procedure }}</td>
              </tr>
              <tr id="video-inference-date">
                <th>Date of Original Inference</th>
                <td>{{foundSpotlight?.created.split('.')[0]}}</td>
              </tr>
              <tr id="video-total-frames">
                <th>totalFrames</th>
                <td>{{foundSpotlight?.totalFrames}}</td>
              </tr>
              <tr id="video-status">
                <th>Status</th>
                <td>{{foundSpotlight?.overallStatus}}</td>
              </tr>
              <tr id="video-tags">
                <th>Tags</th>
                <td>
                  <div style="font-weight:bold;" v-for="tag in foundSpotlight?.tags" :key="tag?.name">
                    {{ tag }}
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div style="display:flex ; justify-content:space-between">
            <div style="min-width:200px">
              <h2>Metric Info</h2>
              <table>
                <tr id="video-kpi1">
                  <th>KPI1</th>
                  <td>{{ kpiResults[0]?.kpi1 !== -1 || 0 ? kpiResults[0]?.kpi1.toFixed(2): null}}</td>
                </tr>
                <tr id="video-kpi2">
                  <th>KPI2</th>
                  <td>{{ kpiResults[0]?.kpi2 !== -1 || 0 ? kpiResults[0]?.kpi2.toFixed(2): null }}</td>
                </tr>
                <tr id="video-kpi3">
                  <th>KPI3</th>
                  <td>{{ kpiResults[0]?.kpi3 !== -1 || 0 ? kpiResults[0]?.kpi3.toFixed(2): null }}</td>
                </tr>
                <tr id="video-kpi4">
                  <th>KPI4</th>
                  <td>{{ kpiResults[0]?.kpi4 !== -1 || 0 ? kpiResults[0]?.kpi4.toFixed(2): null }}</td>
                </tr>
                <tr id="video-map">
                  <th>mAP</th>
                  <td>{{ kpiResults[0]?.mAP !== -1 || 0 ? kpiResults[0]?.mAP.toFixed(2): null }}</td>
                </tr>
              </table>
            </div>
            <div style="min-width:600px">
              <h2>Inference Info</h2>
              <table>
                <tr id="video-model-name">
                  <th>Model Name</th>
                  <td>{{ foundSession?.model }}</td>
                </tr>
                <tr id="video-inference-session">
                  <th>Inference Session</th>
                  <td>{{ foundSpotlight?.session }}</td>
                </tr>
                <tr id="video-units">
                  <th>Units</th>
                  <td></td>
                </tr>
                <tr id="video-boxes-touched">
                  <th>numBboxesTouched</th>
                  <td>{{foundSpotlight?.numBboxesTouched}}</td>
                </tr>
                <tr id="video-frames-touched">
                  <th>numFramesTouched</th>
                  <td>{{foundSpotlight?.numFramesTouched}}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="video-breakdown__table__content grid" style="flex: 1" id="video-steps-table">
        <!-- <span class="header">Date</span> -->
        <span class="header">Step</span>
        <span class="header">Step Number</span>
        <span class="header">Class</span>
        <span class="header">frame</span>
        <template
          v-for="step in kpiResults[0]?.steps"
          :key="step?.name"
        >
          <!-- <span>{{foundkpiResult?.created.split('.')[0]}}
          </span> -->
          <span id="step">{{ step.step }}
          </span>
          <span id="step-id">{{ step.stepId }}
          </span>
          <span id="step-class">{{ mapping[step.classification] }}
          </span>
          <span id="step-frame">{{ step.frame }}
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, ref } from 'vue'
import useSpotlight from '@/models/get/spotlight_video'
import useKpiResults from '@/models/list/kpi_results'
import useSession from '@/models/get/session'
import debounce from 'lodash/debounce'
import VideoPlayer from '@/components/VideoPlayer.vue'
import { Storage } from 'aws-amplify'
import { useStorage } from '@/amplify-utils/storage'
import { until } from '@vueuse/core'

export default defineComponent({
  name: 'VideoBreakdown',
  components: {
    VideoPlayer,
  },
  props: {
    spotlightId: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    const { fetchItem: fetchSpotlight, item: foundSpotlight } = useSpotlight()
    const { fetchItem: fetchSession, item: foundSession } = useSession()
    const { fetchItems: fetchKpiResults, items: kpiResults } = useKpiResults()
    const bucket = computed(() => String(foundSpotlight.value?.videoPath.split('/')[2]))
    const bucketInfer = ref('nflux-pma-edge-nokia-chennai')
    const { url, fetch } = useStorage(bucket)
    const { url: urlInfer, error: errorInfer, fetch: fetchInfer } = useStorage(bucketInfer)
    const inferEnabled = ref(true)
    const videoURL = ref('')
    const sources = computed(() => videoURL.value !== ''
      ? [
          {
            src: videoURL.value,
            type: inferEnabled.value ? 'video/webm' : 'application/x-mpegURL',
          },
        ]
      : [])

    const handleErr = (err) => {
      console.error(err)
      errorInfer.value = err.name + ': ' + err.message
    }
    const mapping = { TRUE_POSITIVE: 'TF', FALSE_POSITIVE: 'FP', TRUE_NEGATIVE: 'TN', FALSE_NEGATIVE: 'FN' }
    const options = computed(() => {
      return {
        sources: sources.value,
        controls: true,
        autoplay: false,
        muted: true,
        preload: 'auto',
        aspectRatio: '4:3',
        fill: true,
        playbackRates: [0.25, 0.5, 1, 1.5, 2, 3, 5, 10, 15],
      }
    })

    // const changeVideoSource = async () => {
    //   if (url.value === '') { return }

    //   inferEnabled.value = !inferEnabled.value
    //   if (inferEnabled.value) {
    //     videoURL.value = url.value || ''
    //   } else {
    //     await loadGT()
    //   }
    // }

    const loadInfer = async () => {
      const prefix = 'files/inferences/' + foundSpotlight.value.name + '/' + foundSpotlight.value.filebase
      try {
        console.log('Try fetching inference')
        console.log(prefix)
        const list = await Storage.list(prefix, {
          customPrefix: { public: '' },
          bucket: bucketInfer.value,
          region: 'us-west-2',
        })
        if (list.length > 0) {
          console.log('Found!!')
          const key = String(list[0].key)
          await fetchInfer(key)
          videoURL.value = urlInfer.value
        }
      } catch (err) {
        console.log('Not Found!!')
        handleErr(err)
      }

      // let temp = ''
      // const arr = foundSpotlight.value.videoPath.split('/')
      // for (let i = 3; i < arr.length; i++) {
      //   temp += arr[i] + '/'
      // }
      // temp = temp.slice(0, -1)
      // console.log(temp)
      // await fetch(temp)
      // const key = 'files/inferences/spotlights/b47c8fdb-c471-4422-9e2e-6b7cb496d4bb/21_12_14_sessions_d8735616-3c1b-4032-812b-6bc6f8d795f4_videos_d826e418-994e-4463-9581-d6671a12cc99_stations-station-4-cameras-station-4-left_od_colors.webm'
      // await fetch(key)
      // await fetch(prefix)
    }

    const loadGT = async () => {
      let temp = ''
      const arr = foundSpotlight.value.videoPath.split('/')
      for (let i = 3; i < arr.length; i++) {
        temp += arr[i] + '/'
      }
      temp = temp.slice(0, -1)
      try {
        await fetch(temp)
        videoURL.value = url.value
      } catch (err) {
        console.log(err)
      }
    }

    const fecthVideo = async () => {
      try {
        await until(bucket)
          .not
          .toBeUndefined({ timeout: 1000 })
        await loadInfer()
        if (!videoURL.value) {
          await loadGT()
        }
      } catch (err) {
        console.log('Timeout:', err)
      }
    }

    onMounted(async () => {
      await Promise.all([
        fetchAll(),
      ])
    })

    const fetchAll = debounce(async () => {
      await fetchSpotlight({ spotlight: props.spotlightId })
      console.log(foundSpotlight.value)
      const ID = String(foundSpotlight.value.name?.split('/')[1])
      await fetchKpiResults({ spotlight: ID })
      await fetchSession({ session: foundSpotlight.value.session?.split('/')[1] })
      await fecthVideo()
    }, 250)

    return {
      foundSpotlight,
      options,
      foundSession,
      kpiResults,
      mapping,
    }
  },
})
</script>

<style lang="sass">
@use '@/styles/abstracts' as abs
@use '@/styles/components/_layout' as l

table
  width: 100%
  border-collapse: collapse
  tr
    border-bottom: solid 2px white

  tr:last-child
    border-bottom: none

  th
    border-right: solid 2px white
    position: relative
    width: 30%
    background-color: #7d7d7d
    color: white
    text-align: center
    padding: 10px 0

  th:after
    display: block
    content: ""
    width: 0px
    height: 0px
    position: absolute
    top: calc(50% - 10px)
    right:-10px
    border-left: 10px solid #7d7d7d

  td
    text-align: left
    width: 100%
    text-align: center
    background-color: #eee
    padding: 10px 10px
    border-right: solid 2px white

.video-info
  display: flex
  background: white
  color: black
  padding: 5px
  justify-content: space-between
  align-items: center

.video
  margin:5px
  min-width:600px

.annotation
  $COLS: 2
  margin: abs.$spacing-medium

  .hidden
    display: none

  &__table
    margin-right: abs.$spacing-medium

    &__title
      background-color: darken(abs.$colors-primary-5, 6)
      border-radius: abs.$spacing-extra-small abs.$spacing-extra-small 0 0
      padding: calc(abs.$spacing-large / 2) abs.$spacing-medium

    &__content
      // overflow-y: auto
      grid-template-columns: repeat($COLS, auto)
      grid-auto-rows: minmax(min-content, max-content)
      grid-gap: 1px 2px
      margin-bottom: abs.$spacing-large

      /* Header */
      & > *:nth-child(-n + #{$COLS})
        font-weight: abs.$fonts-bold
        font-size: abs.$fonts-small
        background-color: abs.$colors-light
        color: abs.$colors-dark-1
        position: sticky
        top: 0
        border-bottom: 2px solid gray

      /* Cells */
      & > *
        column-gap: abs.$spacing-small
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap
        padding: 0 abs.$spacing-extra-small
        background-color: abs.$colors-light
        color: abs.$colors-dark-1

.video-breakdown
  $COLS: 4
  margin: abs.$spacing-medium

  .hidden
    display: none

  &__table
    margin-right: abs.$spacing-medium

    &__title
      background-color: darken(abs.$colors-primary-5, 6)
      border-radius: abs.$spacing-extra-small abs.$spacing-extra-small 0 0
      padding: calc(abs.$spacing-large / 2) abs.$spacing-medium

    &__content
      // overflow-y: auto
      grid-template-columns: repeat($COLS, auto)
      grid-auto-rows: minmax(min-content, max-content)
      grid-gap: 1px 2px
      margin-bottom: abs.$spacing-large

      /* Header */
      & > *:nth-child(-n + #{$COLS})
        font-weight: abs.$fonts-bold
        font-size: abs.$fonts-small
        background-color: abs.$colors-light
        color: abs.$colors-dark-1
        position: sticky
        top: 0
        border-bottom: 2px solid gray

      /* Cells */
      & > *
        column-gap: abs.$spacing-small
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap
        padding: 0 abs.$spacing-extra-small
        background-color: abs.$colors-primary-3
        color: abs.$colors-light

      /* Even rows */
      @for $i from 1 through $COLS
        & > *:nth-child(#{$COLS * 2}n + #{$i})
          background-color: abs.$colors-light
          color: abs.$colors-dark-1
</style>
