import { Ref } from 'vue'

import { api } from '@/main'
import useGetModel from './generic'
import { AiSpotlightVideo } from '@/api/spotlight_video'

type GetArgs = Parameters<typeof api.spotlightVideos.spotlightVideosGetSpotlightVideo>
type UpdateArgs = Parameters<typeof api.spotlightVideos.spotlightVideosUpdateSpotlightVideo>
const get = (...args: GetArgs) => api.spotlightVideos.spotlightVideosGetSpotlightVideo(...args)
const update = (...args: UpdateArgs) => api.spotlightVideos.spotlightVideosUpdateSpotlightVideo(...args)

export default (): {
    readonly item: Ref<AiSpotlightVideo>;
    fetchItem: (...args: GetArgs) => void;
    // eslint-disable-next-line
    fetchByName: (name: string, options?:any) => void;
    readonly error: Ref<boolean>;
    readonly loading: Ref<boolean>;
    clearError: () => void;
} => useGetModel(get, update)
