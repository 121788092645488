import { Storage } from 'aws-amplify'
import { ref } from 'vue'
import type { Ref } from 'vue'

export const useStorage = (bucket: string): {
    url: Ref<string>,
    error: Ref<string>,
    fetch: (key: string) => Promise<void>,
    updateBucket: (bucket: string) => void
} => {
  const url = ref<string>('')
  const error = ref<string>('')
  const rbucket = ref<string>(bucket)

  const fetch = async (key: string) => {
    try {
      const signedUrl = await Storage.get(
        key,
        {
          customPrefix: { public: '' },
          bucket: rbucket.value,
          region: 'us-west-2',
          expires: 3600,
        },
      )
      url.value = signedUrl.toString()
    } catch (err) {
      error.value = err.toString()
    }
  }

  const updateBucket = (bucket: string) => {
    rbucket.value = bucket
  }

  return {
    url,
    error,
    fetch,
    updateBucket,
  }
}

export const signedUrl = (key: string): string => {
  let url = key

  Promise.resolve(
    Storage.get(
      key,
      {
        customPrefix: { public: '' },
        expires: 3600,
      },
    ).then(v => { url = v.toString() }),
  )

  return url
}
